import Overlay from "components/overlay";
import { useEffect, useState } from "react";
import Typist from "react-typist";
import { Cloud, Content, ContentLeft, ContentRight, Main, RotateHover, RotateTap } from "styles/hero";
import { Button, H1, MySwal, P, Section } from "styles/main";

const Hero = () => {
   const [count, setCount] = useState(1);

   useEffect(() => {
      if (count === 0) {
         setCount(1);
      }
   }, [count]);

   const Download = () => {
      MySwal.fire({
         title: "Please Wait !",
         html: "Checking connection ...",
         allowOutsideClick: false,
         confirmButtonColor: "black",
         didOpen: () => {
            MySwal.showLoading();
            setTimeout(() => {
               MySwal.fire({
                  title: "Failed to Download!",
                  html: "Something went wrong.",
                  allowOutsideClick: false,
                  icon: "error",
                  confirmButtonColor: "black",
               });
            }, 3000);
         },
      });
   };

   return (
      <Main>
         <Overlay index={1} />
         <Section index={1} data-aos="fade-up-left" data-aos-duration="3000">
            <Content>
               <ContentLeft>
                  <P data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear">
                     Hi!
                  </P>
                  <H1 data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear">
                     {count && (
                        <Typist onTypingDone={() => setCount(0)} cursor={{ show: true, blink: true, element: "|" }}>
                           <Typist.Delay ms={2500} />
                           I'm Jayar Iglesias
                           <Typist.Delay ms={2000} />
                           <Typist.Backspace count={16} delay={1000} />
                        </Typist>
                     )}
                  </H1>
                  <P data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear">
                     Web / Mobile Developer
                  </P>
                  <Button
                     marginTop="25px"
                     variant="dark"
                     size="lg"
                     data-aos="fade-up"
                     data-aos-duration="3000"
                     data-aos-easing="linear"
                     onClick={() => Download()}
                  >
                     Download App
                  </Button>
               </ContentLeft>
               <ContentRight>
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["65%", "70%", "65%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 7,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 0,
                     }}
                     drag={true}
                     dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["50%", "55%", "50%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 7.5,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["20%", "25%", "20%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 8,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["10%", "15%", "10%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 8.5,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["-20%", "-15%", "-20%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 9,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["-22%", "-20%", "-22%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 7,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: -500, right: 100, top: -100, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["-42%", "-40%", "-42%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 7.5,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: -500, right: 100, top: -100, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["-57%", "-55%", "-57%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 8,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: -500, right: 100, top: -100, bottom: 100 }}
                  />
                  <Cloud
                     whileHover={RotateHover}
                     whileTap={RotateTap}
                     animate={{
                        left: ["-77%", "-75%", "-77%"],
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["45%", "50%", "45%"],
                     }}
                     transition={{
                        duration: 8.5,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        loop: Infinity,
                        repeatDelay: 1,
                     }}
                     drag={true}
                     dragConstraints={{ left: -500, right: 100, top: -100, bottom: 100 }}
                  />
               </ContentRight>
            </Content>
         </Section>
      </Main>
   );
};

export default Hero;
