import styled, { keyframes } from "styled-components";

const animation = keyframes`
    from {
        left: -100vw;
        opacity: 1;
    }
    to {
        left: -200vw;
        opacity: 0;
    }
`;

const Anima = styled.div`
   height: 780px;
   width: 100%;
   z-index: ${(props) => props.index || 1};
   position: absolute;
   overflow: hidden;
`;

const Line = styled.div`
   position: relative;
   height: 34%;
   background: gray;
   width: 200%;
   &:nth-child(1) {
      animation: ${animation} 0.75s ease-in-out forwards;
   }
   &:nth-child(2) {
      background-color: white;
      animation: ${animation} 0.95s ease-in-out forwards;
   }
   &:nth-child(3) {
      animation: ${animation} 1.5s ease-in-out forwards;
   }
`;

export default function overlay(props) {
   return (
      <Anima index={props.index}>
         <Line />
         <Line />
         <Line />
      </Anima>
   );
}
