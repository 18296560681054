import About from "components/about";
import Footer from "components/footer";
import Hero from "components/hero";
import Navigator from "components/navigator";
import Skills from "components/skills";
import Viewport from "components/viewport";
import { Container } from "styles/main";

const Home = () => {
   return (
      <Container>
         <Navigator />
         <Hero />
         <About />
         <Skills />
         <Footer />
         <Viewport />
      </Container>
   );
};

export default Home;
