import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import Public from "views/public";

export default function App() {
   return (
      <Router>
         <Switch>
            <Public />
         </Switch>
      </Router>
   );
}
