import { Button as BsButton } from "react-bootstrap";
import styled from "styled-components";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const MySwal = withReactContent(Swal);

export const Container = styled.div`
   background-color: black;
   overflow: hidden;
`;

export const Section = styled.div`
   z-index: ${(props) => props.index};
   margin: ${(props) => (props.margin === false ? "-50px" : "70px")} auto;
   width: ${(props) => props.width || "1200px"};
   height: ${(props) => props.height};
   max-width: 1200px;
   padding: 15px 85px;
   background-color: ${(props) => props.background};
   @media screen and (max-width: 900px) {
      padding: 0px;
   }
`;
export const H1 = styled.h1`
   font-weight: bolder;
   font-size: 40px;
   color: ${(props) => props.color || "white"};
   @media screen and (max-width: 900px) {
      font-size: 30px;
   } ;
`;
export const P = styled.h3`
   font-size: 20px;
   color: ${(props) => props.color || "gray"};
   @media screen and (max-width: 900px) {
      font-size: 20px;
      margin: 20px auto;
      width: 100%;
   } ;
`;

export const Button = styled(BsButton)`
   background-color: black;
   padding: 10px 30px;
   margin-top: ${(props) => props.marginTop};
   width: ${(props) => props.width};
   @media screen and (max-width: 900px) {
      font-size: 15px;
      padding: 10px 10px;
      align-self: center;
      width: 50%;
   } ;
`;
