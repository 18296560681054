import { motion } from "framer-motion";
import styled from "styled-components";

export const Main = styled.div`
   display: flex;
   position: relative;
   height: 780px;
`;
export const Content = styled.div`
   display: flex;
   justify-content: space-between;
   height: 768px;
   @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
   } ;
`;

export const Cloud = styled(motion.div)`
   border-radius: 50%;
   background-color: rgba(235, 235, 235, 1);
   position: relative;
   padding: 15%;
   @media screen and (max-width: 900px) and (orientation: landscape) {
      padding: 12%;
   } ;
`;

export const ContentLeft = styled.div`
   flex: 1;
   padding-top: 150px;
   @media screen and (max-width: 900px) {
      flex: 0.7;
      padding-top: 0px;
      margin: auto 25px;
      text-align: center;
   } ;
`;
export const ContentRight = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   flex: 1;
   padding: 50px;
   @media screen and (max-width: 900px) {
      padding: 30px 80px;
      flex: 0.3;
   }
   ${Cloud}:nth-child(1) {
      top: -30%;
      left: 70%;
   }
   ${Cloud}:nth-child(2) {
      top: -40%;
      left: 55%;
   }
   ${Cloud}:nth-child(3) {
      top: -25%;
      left: 25%;
   }
   ${Cloud}:nth-child(4) {
      top: -30%;
      left: 15%;
   }
   ${Cloud}:nth-child(5) {
      top: -40%;
      left: -10%;
   }
   ${Cloud}:nth-child(6) {
      top: 0;
      left: -20%;
   }
   ${Cloud}:nth-child(7) {
      top: -15%;
      left: -40%;
   }
   ${Cloud}:nth-child(8) {
      top: 0;
      left: -55%;
   }
   ${Cloud}:nth-child(9) {
      top: -10%;
      left: -75%;
   }
`;

export const RotateTap = {
   scale: [1, 1.15, 1],
   background: "white",
   rotate: [0, 0, 270, 270, 0],
   borderRadius: ["50%", "35%", "50%"],
   transition: {
      duration: 0.25,
      ease: "easeInOut",
      times: [0, 0.2, 0.5, 0.8, 1],
      loop: Infinity,
      repeatDelay: 0,
   },
};

export const RotateHover = {
   scale: [1, 1.15, 1],
   background: "white",
   rotate: [0, 0, 270, 270, 0],
   borderRadius: ["50%", "35%", "50%"],
   transition: {
      duration: 1,
      ease: "easeInOut",
      times: [0, 0.2, 0.5, 0.8, 1],
      loop: Infinity,
      repeatDelay: 0.5,
   },
};
