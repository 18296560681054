import React from "react";
import Head from "react-document-configuration";
import ReactDOM from "react-dom";
import Foot from "react-messenger-customer-chat";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
   <React.StrictMode>
      <Head title="Portfolio" />
      <App />
      <Foot pageId="101758835366378" appId="477875536694946" />
   </React.StrictMode>,
   document.getElementById("root")
);

reportWebVitals();
