import { useEffect, useState } from "react";
import { MySwal } from "styles/main";
import { Bars, DayMode, Left, Link, Menu, Nav, NightMode, Right } from "styles/navigator";

export default function Navigator() {
   const [burger, setBurger] = useState(false);
   const [theme, setTheme] = useState(NightMode);

   useEffect(() => {
      window.addEventListener("scroll", () => {
         if (window.scrollY > 100) {
            setTheme(DayMode);
         } else {
            setTheme(NightMode);
         }
      });
   }, []);

   const scrollTo = (x) => {
      window.scrollTo(0, x);
      setBurger(!burger);
   };

   const Download = () => {
      MySwal.fire({
         title: "Please Wait !",
         html: "Checking connection ...",
         allowOutsideClick: false,
         confirmButtonColor: "black",
         didOpen: () => {
            MySwal.showLoading();
            setTimeout(() => {
               MySwal.fire({
                  title: "Failed to Download!",
                  html: "CV is no longer accessible to the public and is in private.",
                  allowOutsideClick: false,
                  icon: "error",
                  confirmButtonColor: "black",
               });
            }, 1500);
         },
      });
   };

   return (
      <Nav {...theme}>
         <Left {...theme}>
            <Link onClick={() => scrollTo(0)} {...theme} size={20} weight="bolder" to="/">
               JEEE
            </Link>
            <Bars
               {...theme}
               size={30}
               onClick={() => {
                  setBurger(!burger);
               }}
            />
         </Left>
         <Menu {...theme} open={burger}>
            <Link onClick={() => scrollTo(550)} {...theme} to="/about">
               About
            </Link>
            <Link onClick={() => scrollTo(1350)} {...theme} to="/skills">
               Skills
            </Link>
            <Link onClick={() => scrollTo(2500)} {...theme} to="/contact">
               Contact
            </Link>
            <Link {...theme} to="/" target="_blank" onClick={() => window.open("https://github.com/jayariglesias")}>
               Projects
            </Link>
         </Menu>
         <Right {...theme} open={burger}>
            <Link
               to="#"
               onClick={(e) => {
                  e.preventDefault();
                  Download();
               }}
            >
               Download CV
            </Link>
         </Right>
      </Nav>
   );
}
