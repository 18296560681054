import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Typist from "react-typist";
import styled from "styled-components";
import { RotateHover, RotateTap } from "styles/hero";
import { H1, P, Section } from "styles/main";

const Content = styled.div`
   display: flex;
   justify-content: center;
   height: 600px;
   @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      width: 100vw;
   } ;
`;

const Cloud = styled(motion.div)`
   border-radius: 50%;
   background-color: rgba(235, 235, 235, 1);
   position: relative;
   padding: 10%;
   margin: 2.5%;
   @media screen and (max-width: 900px) {
      padding: 0;
      height: 50px;
      width: 50px;
   }
`;

const ContentLeft = styled.div`
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   @media screen and (max-width: 900px) {
      padding-top: 0px;
      margin: auto 25px;
      text-align: center;
   } ;
`;
const ContentRight = styled.div`
   position: relative;
   flex: 1;
   @media screen and (max-width: 900px) {
      padding-top: 0px;
      margin: auto 25px;
      text-align: center;
   } ;
`;

const About = () => {
   const [count, setCount] = useState(1);

   useEffect(() => {
      setCount(1);
   }, [count]);

   return (
      <Section margin={false}>
         <Content>
            <ContentLeft data-aos="fade-right" data-aos-duration="2000">
               <Cloud
                  whileHover={RotateHover}
                  whileTap={RotateTap}
                  animate={{
                     scale: [1, 1.2, 1.2, 1, 1],
                     rotate: [0, 0, 270, 270, 0],
                     borderRadius: ["45%", "50%", "45%"],
                  }}
                  transition={{
                     duration: 5,
                     ease: "easeInOut",
                     times: [0, 0.2, 0.5, 0.8, 1],
                     loop: Infinity,
                     repeatDelay: 1,
                  }}
                  drag={true}
                  dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
               />
               <Cloud
                  whileHover={RotateHover}
                  whileTap={RotateTap}
                  animate={{
                     scale: [1, 1.2, 1.2, 1, 1],
                     rotate: [0, 0, 270, 270, 0],
                     borderRadius: ["45%", "50%", "45%"],
                  }}
                  transition={{
                     duration: 5.5,
                     ease: "easeInOut",
                     times: [0, 0.2, 0.5, 0.8, 1],
                     loop: Infinity,
                     repeatDelay: 1,
                  }}
                  drag={true}
                  dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
               />
               <Cloud
                  whileHover={RotateHover}
                  whileTap={RotateTap}
                  animate={{
                     scale: [1, 1.2, 1.2, 1, 1],
                     rotate: [0, 0, 270, 270, 0],
                     borderRadius: ["45%", "50%", "45%"],
                  }}
                  transition={{
                     duration: 6,
                     ease: "easeInOut",
                     times: [0, 0.2, 0.5, 0.8, 1],
                     loop: Infinity,
                     repeatDelay: 1,
                  }}
                  drag={true}
                  dragConstraints={{ left: 0, right: 100, top: 0, bottom: 100 }}
               />
            </ContentLeft>
            <ContentRight>
               <H1 data-aos="fade-up-right" data-aos-duration="2000">
                  WHO AM I
               </H1>
               <P data-aos="fade-up-left" data-aos-duration="3000">
                  {count && (
                     <Typist onTypingDone={() => setCount(0)} cursor={{ show: true, blink: true, element: "|" }}>
                        I’m an innovative Web / Mobile Developer with over a year of experience managing all aspects of
                        the development process for small to medium-sized companies.
                        <Typist.Delay ms={3000} />
                     </Typist>
                  )}
               </P>
            </ContentRight>
         </Content>
      </Section>
   );
};

export default About;
