import emailjs from "emailjs-com";
import { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { Button, P, Section } from "styles/main";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Content = styled.div`
   display: flex;
   justify-content: space-around;
   position: relative;
   @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      width: 100vw;
   } ;
`;

const Grid = styled.div`
   display: flex;
   padding-top: 15px;
   flex-direction: column;

   @media screen and (max-width: 900px) {
      margin: 20px auto;
      text-align: center;
      width: 75%;
      &:nth-child(3) {
         text-align: left;
         &:nth-child(3) ${P} {
            text-align: center;
         }
         &:nth-child(3) ${Button} {
            width: 100%;
         }
      }
   } ;
`;

const A = styled.a`
   position: relative;
   color: gray;
   cursor: pointer;
   margin: 5px 0px;
   font-size: 15px;

   &:hover {
      text-decoration: none;
      color: rgba(220, 220, 220, 1);
   }

   &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 0;
      height: 2px;
      background: white;
      transition: width 0.3s ease-in-out;
   }

   @media screen and (min-width: 900px) {
      &:hover::after {
         width: 25px;
      }
   }

   @media screen and (max-width: 900px) {
      padding: 2px;
      margin: 0;
   }
`;

const Footer = () => {
   const formRef = useRef();
   const MySwal = withReactContent(Swal);

   const [data, setData] = useState({
      to: "Jayar",
      name: "",
      email: "",
      message: "",
   });

   const onInputchange = (e) => {
      setData({ ...data, [e.target.name]: e.target.value });
   };

   const submit = () => {
      if (!data.name || !data.email || !data.message) {
         MySwal.fire({
            title: "Please Fill all Fields!",
            html: "Something went wrong!",
            allowOutsideClick: false,
            icon: "error",
            confirmButtonColor: "black",
         });
         return;
      }

      if (!data.email) {
         MySwal.fire({
            title: "Invalid Email!",
            html: "Something went wrong!",
            allowOutsideClick: false,
            icon: "error",
            confirmButtonColor: "black",
         });
         return;
      }

      MySwal.fire({
         title: "Please Wait !",
         html: "Sending",
         allowOutsideClick: false,
         confirmButtonColor: "black",
         didOpen: () => {
            MySwal.showLoading();
            emailjs
               .send(
                  process.env.REACT_APP_MAIL_SERVICE_ID,
                  process.env.REACT_APP_MAIL_TEMPLATE_ID,
                  data,
                  process.env.REACT_APP_MAIL_USER_ID
               )
               .then(
                  (response) => {
                     MySwal.fire("Success!", "Message Sent!", "success");
                     MySwal.fire({
                        title: "Success!",
                        html: "Message Sent!",
                        allowOutsideClick: false,
                        icon: "Success",
                        confirmButtonColor: "black",
                     });
                     formRef.current.reset();
                  },
                  (err) => {
                     MySwal.fire({
                        title: "Failed to Send!",
                        html: "Something went wrong!",
                        allowOutsideClick: false,
                        icon: "error",
                        confirmButtonColor: "black",
                     });
                  }
               );
         },
      });
   };

   return (
      <Section>
         <Content>
            <Grid>
               <P data-aos="zoom-in" data-aos-duration="3000" color="white">
                  PROFILE
               </P>
               <A
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  href="https://www.github.com/jayariglesias"
                  target="blank"
               >
                  Github
               </A>
               <A
                  data-aos="fade-left"
                  data-aos-duration="3000"
                  href="https://www.linkedin.com/in/jayariglesias/"
                  target="_blank"
               >
                  LinkedIn
               </A>
               <A
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  href="https://www.facebook.com/jayar049"
                  target="_blank"
               >
                  Facebook
               </A>
               <A
                  data-aos="fade-left"
                  data-aos-duration="3000"
                  href="https://www.instagram.com/jayariglesias/"
                  target="_blank"
               >
                  Instagram
               </A>
               <A
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  href="https://www.twitter.com/jayariglesias"
                  target="_blank"
               >
                  Twitter
               </A>
            </Grid>
            <Grid>
               <P data-aos="zoom-in" data-aos-duration="3000" color="white">
                  CONTACT
               </P>
               <A data-aos="fade-right" data-aos-duration="3000">
                  S - 09618535233
               </A>
               <A data-aos="fade-left" data-aos-duration="3000">
                  G - 09454833227
               </A>
               <A data-aos="fade-up" data-aos-duration="3000">
                  Muntinlupa City
               </A>
            </Grid>
            <Grid>
               <P data-aos="zoom-in" data-aos-duration="3000" color="white">
                  GET IN TOUCH
               </P>
               <Form ref={formRef}>
                  <Form.Group data-aos="fade-up-right" data-aos-duration="3000">
                     <A>Name</A>
                     <Form.Control name="name" required="required" autoComplete="off" onChange={onInputchange} />
                  </Form.Group>
                  <Form.Group data-aos="fade-up-left" data-aos-duration="3000">
                     <A>Email</A>
                     <Form.Control name="email" required="required" autoComplete="off" onChange={onInputchange} />
                  </Form.Group>
                  <Form.Group data-aos="fade-up-right" data-aos-duration="3000">
                     <A>Message</A>
                     <Form.Control
                        name="message"
                        as="textarea"
                        rows={3}
                        required="required"
                        autoComplete="off"
                        onChange={onInputchange}
                     />
                  </Form.Group>
                  <Form.Group data-aos="fade-up" data-aos-duration="3000">
                     <Button width="100%" variant="dark" size="sm" onClick={() => submit()}>
                        Submit
                     </Button>
                  </Form.Group>
               </Form>
            </Grid>
         </Content>
      </Section>
   );
};

export default Footer;
