import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import { Container } from "styles/main";
import Home from "views/public/home";

export default function Index() {
   useEffect(() => {
      AOS.init({
         duration: 5000,
      });
   }, []);

   return (
      <Container>
         <Route exact path="/(|work|home|about|skills|contact)" component={Home} />
      </Container>
   );
}
