import styled from "styled-components";
import { H1, Section } from "styles/main";

const Content = styled.div`
   display: flex;
   justify-content: center;
   flex-direction: column;
   height: 600px;
   @media screen and (max-width: 900px) {
      width: 100vw;
   } ;
`;

const Grid = styled.div`
   display: flex;
   padding-top: 15px;
   flex-direction: column;
   @media screen and (max-width: 900px) {
      padding-top: 5px;
      margin: 0px auto;
      text-align: center;
   } ;
`;

const A = styled.a`
   position: relative;
   color: gray;
   cursor: pointer;
   margin: 5px 0px;
   font-size: 15px;

   &:hover {
      text-decoration: none;
      color: rgba(220, 220, 220, 1);
   }

   @media screen and (max-width: 900px) {
      padding: 2px;
      margin: 0;
   }
`;

const ContentLeft = styled.div`
   @media screen and (max-width: 900px) {
      margin-bottom: 10px;
      text-align: center;
   } ;
`;
const ContentRight = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      ${Grid} {
         height: auto;
         width: 50%;
      }
   } ;
`;

const Skills = () => {
   return (
      <Section>
         <Content>
            <ContentLeft>
               <H1 data-aos="zoom-in" data-aos-duration="3000">
                  SKILLS
               </H1>
            </ContentLeft>
            <ContentRight>
               <Grid>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     HTML / CSS
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     JavaScript
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     PHP
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     SQL
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     MongoDB
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     MVC
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     OOP
                  </A>
               </Grid>
               <Grid>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     Node JS
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     Express JS
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     Embedded JS
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     React JS
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     React Native
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     Redux / Context
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     JQuery
                  </A>
               </Grid>
               <Grid>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     REST API
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     AJAX
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     JSON / XML
                  </A>
               </Grid>
               <Grid>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     Git
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     Photoshop
                  </A>
                  <A data-aos="fade-up" data-aos-duration="3000">
                     Figma
                  </A>
               </Grid>
            </ContentRight>
         </Content>
      </Section>
   );
};

export default Skills;
