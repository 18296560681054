import { RiBarChartHorizontalLine as Bar } from "react-icons/ri";
import { Link as ReactLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export const NightMode = {
  background: 'transparent',
  color: 'white',
  underline: 'white'
};

export const DayMode = {
  background: 'white',
  color: 'black',
  underline: 'black'
};

export const topToBottom = keyframes`
	${'0%'} {
		top: -45px;
	}
	${'50%'} {
		top: 45px;
	}
  ${'100%'} {
    top: 0px;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.background};
  position: fixed;
  padding: 40px 55px;
  width: 100%;
  height: 60px;
  top: 0;
  z-index: 1000;
  transition: background-color 1s;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0px;
  }
`;

export const Link = styled(ReactLink)`
  position: relative;
  margin: 0px 15px;
  padding: 15px 20px;
  font-size: ${(props) => props.size || 12}px;
  font-weight: ${(props) => props.weight || "normal"};
  color: ${(props) => props.color};
  background: ${(props) => props.button};

  &:hover {
    text-decoration: none;
    color: gray;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0;
    height: 2px;
    background: ${(props) => props.underline};
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    padding: 12px;
    margin: 5px 15px;
  }
`;

export const Menu = styled.div`
  ${Link}:nth-child(1) {
    animation: ${topToBottom} 2s ease-in-out forwards;
  }

  ${Link}:nth-child(2) {
    animation: ${topToBottom} 2.2s ease-in-out forwards;
  }

  ${Link}:nth-child(3) {
    animation: ${topToBottom} 2.4s ease-in-out forwards;
  }

  ${Link}:nth-child(4) {
    animation: ${topToBottom} 2.6s ease-in-out forwards;
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${props => (props.background === 'transparent' && 'black') || 'white'};
    position: relative;
    transform: ${(props) => props.open ? "translateX(0%)" : "translateX(-200%)"};
    transition: transform 0.8s ease-in-out;
    padding-top: 15px;
    top: -15px;
    z-index: 0;
  }
`;

export const Right = styled.div`
  ${Link}:nth-child(1) {
    background: ${props => props.color};
    color: ${props => (props.background === 'transparent' && 'black') || 'white'};
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    background: ${props => props.background};
    transform: ${(props) => props.open ? "translateX(0%)" : "translateX(-200%)"};
    transition: transform 0.8s ease-in-out;
    padding-bottom: 15px;
    top: -15px;

  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 900px) {
    ${Link}:nth-child(1) {
      color: ${props => props.color}
    }
    width: 100%;
  }
`;

export const Bars = styled(Bar)`
  display: none;
  @media screen and (max-width: 900px) {
    display: flex;
    position: relative;
    padding: 5px;
    margin: 0px 10px;
    cursor: pointer;
  }
`;

